<template>
  <el-dialog :title="title" :visible.sync="visible" width="700px">
    <el-form ref="form" :model="form" label-width="60px" label-position="left" :rules="rules">
      <div>
        <div class="leftTag">基本信息</div>
      </div>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="用户名">
            <el-input disabled v-model="form.Name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="手机号">
            <el-input disabled v-model="form.Phone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div>
        <div class="leftTag">备注信息</div>
      </div>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="昵称" prop="NickName">
            <el-input v-model="form.NickName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别" style="text-align: left">
            <el-radio-group v-model="form.Sex">
              <el-radio label="男">男性</el-radio>
              <el-radio label="女">女性</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="公司">
            <el-input v-model="form.Company"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮箱">
            <el-input v-model="form.Email"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="地址">
        <el-input v-model="form.Address"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.Remarks" type="textarea" :autosize="{ minRows: 4 }"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="cancel">取消</el-button>
      <el-button size="mini" @click="confirm" type="primary">保存</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      visible: false,
      rules: {
        userName: [{ required: true, message: "请输入姓名" }],
        phone: [{ required: true, message: "请输入手机号" }],
      },
      form: {},
      title: ''
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    open(form, title) {
      this.title = title
      this.visible = true
      this.form = form
    },
    confirm() {
      this.form.UserId = this.userInfo.Id;
      this.$http
        .post("/Base_Mail/Base_MailList/SaveData", this.form)
        .then((res) => {
          if (res.Success) {
            this.$message.success('操作成功')
            this.$emit('comfirm', true)
            this.visible = false
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    cancel() {
      this.visible = false
      setTimeout(() => {
        this.form = {}
      }, 100);
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 0 20px;
}

.leftTag {
  margin: 20px 0;
  // margin-left: -20px;
  width: 98px;
  padding: 10px;
  padding-right: 30px;
  background: #d1100b;
  border-radius: 0 100px 100px 0;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}
</style>
