<template>
  <div class="mailTable">
    <div class="topBar">
      <div>
        <el-input v-model="keyword" clearable placeholder="请输入用户名称进行查询" size="medium"
          style="width: 220px;margin-right: 10px;" @clear="search" @keydown.native.enter="search"></el-input>
        <el-button size="mini" type="primary" @click="search">查询</el-button>
      </div>
      <div>
        <el-button size="mini" type="primary" @click="addUser">添加联系人</el-button>
        <el-button size="mini" type="primary" @click="toInvite">邀请</el-button>
      </div>
    </div>
    <el-table :data="data" style="width: 100%">
      <el-table-column label="头像" prop="date" align="center" width="100">
        <template slot-scope="scope">
          <el-avatar :size="40" :src="scope.row.Icon"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column label="姓名" prop="NickName" width="200">
        <template slot-scope="scope">
          {{ scope.row.NickName ? scope.row.NickName + "(" + scope.row.Name + ")" : scope.row.Name }}
        </template>
      </el-table-column>
      <el-table-column label="性别" prop="Sex" align="center" width="100"></el-table-column>
      <el-table-column label="手机号" prop="Phone" align="left" width="150"></el-table-column>
      <el-table-column label="邮箱" prop="Email" align="left"></el-table-column>
      <el-table-column label="地址" prop="Address"></el-table-column>
      <el-table-column label="备注" prop="Remarks"></el-table-column>
      <el-table-column label="账户状态" prop="IsLogout" align="center" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.IsLogout" type="danger" style="font-weight: bold">已注销</el-tag>
          <el-tag v-else type="success">使用中</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" prop="action" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" @click="del(scope.row.Id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="dialogVisible" title="邀请" width="50%">
      <div class="invite">
        <div>
          <p><span>点击链接复制：</span></p>
          <el-button size="mini" type="text" @click="copyUrl">{{ inviteUrl }}</el-button>
        </div>
        <div class="line">
          <div></div>
        </div>
        <div>
          <div ref="qrCodeUrl" class="qrcode"></div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="invitePhoneVisible" title="添加联系人" width="300px">
      <el-input v-model="invitePhone" placeholder="请输入手机号"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="invitePhoneConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <MailForm ref="mailform" @comfirm="MailFormComfirm" />
  </div>
</template>
<script>
const reg = /^1[345789]\d{9}$/;
import QRCode from "qrcodejs2";
import { mapState } from 'vuex';
import MailForm from "./MailForm.vue";

export default {
  components: {
    MailForm,
  },
  data() {
    return {
      data: [],
      inviteUrl: '',// 邀请链接
      allArr: [],// 查询前数据
      keyword: '',
      dialogVisible: false,
      invitePhone: '',
      invitePhoneVisible: false
    }
  },
  mounted() {
    this.getDataList();
    this.inviteUrl =
      location.href.split("M")[0] + "NoT/invite?UserId=" + this.userInfo.Id;
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    // 添加联系人
    async MailFormComfirm() {
      await this.getDataList()
      this.search()
    },
    // 添加联系人手机号录入
    invitePhoneConfirm() {
      if (!reg.test(this.invitePhone))
        return this.$message.error("请输入正确的手机号");
      let obj = JSON.parse(this.allArr).find((item) => item.Phone == this.invitePhone);
      if (obj) {
        this.$refs.mailform.open(obj, '编辑用户')
        this.invitePhoneVisible = false
        return
      }
      this.$http
        .post("/Base_Mail/Base_MailList/GetVIPUserInfoData?Phone=" + this.invitePhone)
        .then((res) => {
          this.loading = false;
          if (res.Success) {
            this.invitePhoneVisible = false
            if (!res.Data) return this.$message.error("未查询到用户");
            let { Name, Phone, Icon, Id: MailUserId } = { ...res.Data };
            let obj = {
              Name,
              Phone,
              Icon,
              MailUserId,
              Sex: "男",
            };
            this.$refs.mailform.open(obj, '添加用户')
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 添加联系人手机号录入弹窗开启
    addUser() {
      this.invitePhone = ''
      this.invitePhoneVisible = true
    },
    // 编辑
    edit(obj) {
      this.$refs.mailform.open(obj, '编辑用户')
    },
    // 删除
    del(Id) {
      this.$confirm("此操作将删除该联系人, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/Base_Mail/Base_MailList/MailListDeleteData", [Id])
            .then(async (res) => {
              console.log(res);
              if (res.Success) {
                await this.getDataList()
                this.search()
              } else this.$message.error(res.Msg);

            });
        })
        .catch(() => {
        });
    },
    // 邀请
    toInvite() {
      this.dialogVisible = true;
      if (!this.$refs.qrCodeUrl) {
        this.$nextTick(() => {
          this.creatQrCode();
        });
      }
    },
    // 复制邀请链接
    copyUrl() {
      var input = document.createElement("input"); // 创建input对象
      input.value = this.inviteUrl; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
    // 列表查询
    search() {
      let arr = JSON.parse(this.allArr);
      if (!this.keyword) return (this.data = arr);
      this.data = arr.filter((item) => {
        if (item.NickName) return item.NickName.indexOf(this.keyword) != -1 || item.Phone.indexOf(this.keyword) != -1;
        else return item.Name.indexOf(this.keyword) != -1 || item.Phone.indexOf(this.keyword) != -1;
      });
    },
    // 数据源
    async getDataList() {
      await this.$http
        .post("/Base_Mail/Base_MailList/GetDataList", {
          SortField: "Initial",
          SortType: "asc",
        })
        .then((res) => {
          if (res.Success) {
            this.allArr = JSON.stringify(res.Data);
            this.data = JSON.parse(this.allArr);
          }
        });
    },
    // 创建邀请二维码
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.inviteUrl, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-avatar img {
  display: block;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

::v-deep .el-table .el-table__cell {
  padding: 12px 0 !important;
}

.mailTable {
  width: 80%;
  margin: 0 auto;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  height: 100%;
  padding-top: 30px;
  box-sizing: border-box;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    /* 滚动条里面小方块 */
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #e0e0e0;
  }

  &::-webkit-scrollbar-track {
    /* 滚动条里面轨道 */
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    // border-radius: 10px;
    background: transparent;
  }

  .topBar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 10px;
  }

  .invite {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    div {
      text-align: left;
    }

    .line {
      width: 1px;
      height: 100%;

      div {
        height: 100px;
        width: 1px;
        background: #ccc;
        margin: 0 auto;
      }
    }
  }
}
</style>
